import * as React from "react";
import Layout from "../components/Layout/Layout";
import HeroParagraph from "../_paragraph/HeroParagraph/HeroParagraph";
import * as styles from "./competenze-leadership.module.css";
import Icon from "../components/Icon/Icon";
const CompetenceLeadership = () => {
  return (
    <Layout>
      <div>
        <section>
          <div className={styles.heroOuter}>
            <div className={styles.heroContainer}>
              <div className={styles.heroHeadline}>
                <h1>
                  Sistemi di Competenze<br></br> + modelli di leadership
                </h1>
              </div>
            </div>
            <div className={styles.heroImage}>
              <Icon icon={"freccette"} />
            </div>
          </div>
        </section>
        <section className={styles.firstFirst}>
          <div className={styles.firstSection}>
            <div className={styles.leftPartContainer}>
              <div>
                <p>
                  Quando le cose cambiano così in fretta c’è una sola competenza
                  che conta davvero…
                </p>
              </div>
              <div className={styles.arrowIconDown}>
                <Icon icon="arrowIconDown"></Icon>
              </div>
            </div>
            <div className={styles.rightPartContainer}>
              <Icon icon="pieRight"></Icon>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.firstSection}>
            <div className={styles.secondContainer}>
              <div className={styles.textImageContainer}>
                <div className={styles.miglioriContainer}>
                  <p>
                    <strong>
                      La capacità di apprendere e di migliorarsi continuamente.
                    </strong>
                  </p>
                </div>
                <div className={styles.businessArrow}>
                  <Icon icon="businessArrow"></Icon>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.outer}>
            <div className={styles.container}>
              <div className={styles.firstPara}>
                <p>
                  Ma c’è un altro fattore che – a nostro modo di vedere –
                  suggerisce l’opportunità di porre la Learning Agility, ovvero
                  un set di capacità sostanzialmente fondato sull’apprendimento,
                  come riferimento essenziale delle politiche di sviluppo
                  manageriale dell’Azienda. E si tratta di un fattore di rilievo
                  strategico, ovvero di quelli destinati a far sentire i propri
                  effetti nel medio e nel lungo periodo, sebbene fin da ora sia
                  possibile osservarne le prime inequivocabili manifestazioni.
                </p>
              </div>
              <div className={styles.secondPara}>
                <p>
                  <strong>
                    Il nostro approccio si basa su questa semplice convinzione,
                    rafforzata dalle sollecitazioni impreviste ed imprevedibili,
                    che hanno investito il mondo del lavoro negli ultimi mesi e
                    che hanno imposto ai leader aziendali – di qualsiasi livello
                    – di modificare improvvisamente la propria strategia per
                    adattarla ad una modalità completamente nuova di interagire
                    con la propria squadra.
                  </strong>
                </p>
              </div>
              <div className={styles.iconPara}>
                <Icon icon="arrowBoldLeft"></Icon>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.outer}>
            <div className={styles.container}>
              <p>
                Il Capitale Umano di ogni organizzazione – già da oggi – non si
                compone soltanto di Baby Boomers (persone nate prima del 1975);
                al suo interno è già presente, ed è destinato ad una rapida e
                progressiva espansione il sottogruppo composto da Millennials
                (persone nate dopo il 1975).
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.outer}>
            <div className={styles.container}>
              <div className={styles.statisticsContainer}>
                <Icon icon="statistics"></Icon>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.outer}>
            <div className={styles.container}>
              <p>
                <strong>
                  Si tratta di persone cresciute con prospettive ed ambizioni
                  professionali completamente diverse rispetto a quelle della
                  generazione immediatamente precedente. Persone abituate ad
                  ascoltare il mantra della flessibilità e della necessità di
                  prepararsi ad un percorso professionale caratterizzato da
                  numerosi cambi di scenario e – conseguentemente – da
                  esperienze di lavoro in aziende diverse. Per costoro la
                  stabilità e l’appartenenza non costituiscono più i fattori
                  guida nella scelta e nella permanenza nella stessa
                  organizzazione.
                </strong>
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.outer}>
            <div className={styles.container}>
              <div className={styles.mockContainer}>
                <Icon icon="arrowBoldLeft"></Icon>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.outer}>
            <div className={styles.container}>
              <p>
                Sono attratti principalmente dalle opportunità di accrescere le
                proprie capacità e le proprie competenze che ciascuna
                collocazione professionale è in grado di garantire. Le aziende
                che si propongono di attrarre i manager più bravi e più
                preparati e di trattenerli il più a lungo possibile nel proprio
                leading team hanno, pertanto, la necessità di costruire intorno
                a loro una organizzazione che sappia valorizzare tutte le
                opportunità di apprendimento che è in grado di offrire.
              </p>
            </div>
          </div>
          <div className={styles.outer}>
            <div className={styles.CTAContainer}>
              <a className={styles.scopriDiPiuCTA} href="#contatti">
                Scopri di più
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default CompetenceLeadership;
