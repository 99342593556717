// extracted by mini-css-extract-plugin
export var CTAContainer = "competenze-leadership-module--CTAContainer--xmlm6";
export var arrowIconDown = "competenze-leadership-module--arrowIconDown--VJWvX";
export var businessArrow = "competenze-leadership-module--businessArrow--1Y6YR";
export var container = "competenze-leadership-module--container--Q5Ftc";
export var firstFirst = "competenze-leadership-module--firstFirst--LSNyF";
export var firstPara = "competenze-leadership-module--firstPara--jHT7L";
export var firstSection = "competenze-leadership-module--firstSection--3foyr";
export var heroContainer = "competenze-leadership-module--heroContainer--yOti-";
export var heroImage = "competenze-leadership-module--heroImage--2SFu5";
export var heroOuter = "competenze-leadership-module--heroOuter--GKD31";
export var iconPara = "competenze-leadership-module--iconPara--yRk3p";
export var leftPartContainer = "competenze-leadership-module--leftPartContainer--aMVBg";
export var miglioriContainer = "competenze-leadership-module--miglioriContainer--G4Cf1";
export var mockContainer = "competenze-leadership-module--mockContainer--FFEdE";
export var outer = "competenze-leadership-module--outer--plEuE";
export var rightPartContainer = "competenze-leadership-module--rightPartContainer--MJeRv";
export var scopriDiPiuCTA = "competenze-leadership-module--scopriDiPiuCTA--vcAJa";
export var secondContainer = "competenze-leadership-module--secondContainer--dDBlA";
export var secondPara = "competenze-leadership-module--secondPara--t9TpR";
export var statisticsContainer = "competenze-leadership-module--statisticsContainer--bnk+l";
export var textImageContainer = "competenze-leadership-module--textImageContainer--0XFtg";